import { html } from "lit-html";

const Loader = () => {
  return html`
    <style>
      .et-loader-element {
        animation: test 0.5s infinite both alternate ease-in-out;
        transform-origin: center;
      }
      .et-loader-element:nth-child(2) {
        animation-delay: 0.15s;
      }
      .et-loader-element:nth-child(3) {
        animation-delay: 0.3s;
      }
      @keyframes test {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1.07);
        }
      }
    </style>

    <svg
      width="40%"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="et-loader-element">
        <rect
          x="14"
          y="30"
          width="99"
          height="19"
          rx="3"
          fill="url(#paint0_linear)"
          fill-opacity="0.38"
        />
        <rect
          x="36"
          y="35"
          width="68"
          height="5"
          rx="2.5"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <rect
          x="36"
          y="42"
          width="37"
          height="2"
          rx="1"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <circle cx="24" cy="40" r="6" fill="#C4C4C4" />
      </g>
      <g class="et-loader-element">
        <rect
          x="14"
          y="54"
          width="99"
          height="19"
          rx="3"
          fill="url(#paint1_linear)"
          fill-opacity="0.38"
        />
        <rect
          x="36"
          y="59"
          width="68"
          height="5"
          rx="2.5"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <rect
          x="36"
          y="66"
          width="37"
          height="2"
          rx="1"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <circle cx="24" cy="64" r="6" fill="#C4C4C4" />
      </g>
      <g class="et-loader-element">
        <rect
          x="14"
          y="78"
          width="99"
          height="19"
          rx="3"
          fill="url(#paint2_linear)"
          fill-opacity="0.38"
        />
        <rect
          x="36"
          y="83"
          width="68"
          height="5"
          rx="2.5"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <rect
          x="36"
          y="90"
          width="37"
          height="2"
          rx="1"
          fill="#C4C4C4"
          fill-opacity="0.39"
        />
        <circle cx="24" cy="88" r="6" fill="#C4C4C4" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="113"
          y1="40"
          x2="14"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4C4C4" />
          <stop offset="1" stop-color="#C4C4C4" stop-opacity="0.28" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="113"
          y1="64"
          x2="14"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4C4C4" />
          <stop offset="1" stop-color="#C4C4C4" stop-opacity="0.28" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="113"
          y1="88"
          x2="14"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4C4C4" />
          <stop offset="1" stop-color="#C4C4C4" stop-opacity="0.28" />
        </linearGradient>
      </defs>
    </svg>
  `;
};

export default Loader;
