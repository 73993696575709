export default {
  type: "inline",
  showBranding: null,
  integrationName: "google-sheets",
  columnsToHide: ["Timestamp"],
  userStyles: {
    showLabels: false,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: "column",
      textAlign: "center",
      boxShadow: {
        x: 3,
        y: 3,
        blur: 10,
        color: "0,0,0",
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: "0,0,0",
      },
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
    },
    image: {
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: "0,0,0",
      },
    },
    button: {
      backgroundColor: "0,0,0",
      textColor: "255,255,255",
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: "left",
      header: {
        backgroundColor: "200,10,100",
        textColor: "245,245,245",
        isBold: true,
      },
    },
  },
  fields: [
    [
      {
        name: "Value",
        value: "1200",
        formattedValue: "1200",
        styles: {
          backgroundColor: { red: 1, green: 1, blue: 1 },
          padding: { top: 2, right: 3, bottom: 2, left: 3 },
          horizontalAlignment: "LEFT",
          verticalAlignment: "BOTTOM",
          wrapStrategy: "OVERFLOW_CELL",
          textFormat: {
            foregroundColor: {},
            fontFamily: "Arial",
            fontSize: 10,
            bold: false,
            italic: false,
            strikethrough: false,
            underline: false,
            foregroundColorStyle: { rgbColor: {} },
          },
          hyperlinkDisplayType: "PLAIN_TEXT",
          backgroundColorStyle: { rgbColor: { red: 1, green: 1, blue: 1 } },
        },
      },
    ],
  ],
};
