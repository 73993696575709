import "embedtables-element";

import { html, render } from "lit-html";

import { until } from "lit-html/directives/until";
import { styleMap } from "lit-html/directives/style-map";

import api from "./api2";

import { get, set, createStore } from "idb-keyval";

import Loader from "./components/Loader";

import calendarOne from "./styles/calendarOne";
import storeBookStore from "./styles/storeBookStore";
import minimal from "./styles/minimal";
import listBasic from "./styles/listBasic";

const getProjectData = (id) => {
  // if (process.env.NODE_ENV === "development") {
  //   return new Promise(function (resolve, reject) {
  //     setTimeout(() => resolve(api), 1000);
  //   });
  // }
  return fetch(`${process.env.LAMBDA_ENDPOINT}/projects/data/${id}`)
    .then((resp) => resp.json())
    .then((resp) => {
      if (embedtablesStore) set(id, resp, embedtablesStore);
      return resp;
    });
};

const stylingProject = ({
  fields,
  type,
  userStyles,
  primaryColor,
  secondaryColor,
  templateSlug,
}) => {
  let processedStyles = null;
  if (templateSlug) {
    switch (templateSlug) {
      case "calendar-basic":
        processedStyles = calendarOne(primaryColor, secondaryColor);
        break;
      case "store-book-store":
        processedStyles = storeBookStore(primaryColor, secondaryColor);
        break;
      case "list-basic-2":
        processedStyles = minimal(primaryColor, secondaryColor);
        break;
      case "list-basic":
        processedStyles = listBasic(primaryColor, secondaryColor);
        break;
    }
  }
  return {
    fields: fields,
    type: type || "cards",
    userStyles: userStyles || processedStyles,
  };
};

const loaderStyles = {
  width: "30%",
  margin: "auto",
  display: "grid",
  placeItems: "center",
};

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const embedtablesStore = !inIframe()
  ? createStore("embedtables", "data")
  : null;

const renderEmbedtablesElement = (data, root) => {
  data.type === "inline"
    ? (root.style.display = "inline")
    : (root.style.display = "block");
  return html`<embedtables-element
    type=${data.type}
    userstyles=${JSON.stringify(data.userStyles)}
    projectdata=${JSON.stringify(data.fields)}
  ></embedtables-element>`;
};

const roots = document.querySelectorAll("div[data-embedtableid]");

const freshDataEvent = new Event("fresh-data");
let freshData = null;

async function projectData(root) {
  const projectId = root.dataset["embedtableid"];
  return new Promise(function (resolve, reject) {
    if (embedtablesStore) {
      get(projectId, embedtablesStore).then((project) => {
        if (!project) {
          getProjectData(projectId).then((projectData) => {
            resolve(
              renderEmbedtablesElement(stylingProject(projectData), root)
            );
          });
        } else {
          getProjectData(projectId).then((projectFetched) => {
            freshData = projectFetched;
            if (JSON.stringify(project) !== JSON.stringify(freshData)) {
              root.dispatchEvent(freshDataEvent);
            }
          });
          resolve(renderEmbedtablesElement(stylingProject(project), root));
        }
      });
    } else {
      getProjectData(projectId).then((projectData) => {
        resolve(renderEmbedtablesElement(stylingProject(projectData), root));
      });
    }
  });
}

(function () {
  roots.forEach(function (root) {
    root.addEventListener("fresh-data", (event) => {
      render(
        renderEmbedtablesElement(stylingProject(freshData), event.target),
        root
      );
    });
    render(
      until(
        projectData(root),
        html`${root.dataset["embedtableLoader"] === "off"
          ? null
          : html`<div style=${styleMap(loaderStyles)}>${Loader()}</div>`}`
      ),
      root
    );
  });
})(window);
