const minimal = (primaryColor = "0,0,0", secondaryColor = "255,255,255") => {
  return {
    showLabels: false,
    cards: {
      columns: 4,
    },
    singleElement: {
      direction: "column",
      textAlign: "left",
      justifyContent: "flex-start",
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: "0,0,0",
      },
      border: {
        top: 1,
        bottom: 1,
        left: 1,
        right: 1,
        color: "200,200,200",
      },
      borderRadius: {
        topLeft: 15,
        topRight: 15,
        bottomRight: 15,
        bottomLeft: 15,
      },
    },
    image: {
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: "0,0,0",
      },
    },
    button: {
      backgroundColor: primaryColor,
      textColor: secondaryColor,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: "left",
      header: {
        backgroundColor: "200,10,100",
        textColor: "245,245,245",
        isBold: true,
      },
    },
  };
};
export default minimal;
